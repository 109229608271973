body {
  background-color: #00f;
}

.week0 {
  background-color: red;
}

.week1 {
  background-color: #ff0;
}

/*# sourceMappingURL=index.1332338f.css.map */
