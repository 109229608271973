body {
  background-color: blue;
}

.week0 {
  background-color: red;
}

.week1 {
  background-color: yellow;
}
